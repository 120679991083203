.go-to-connect-hub {
  position: relative;
  overflow: hidden;
  /* z-index: 1; */

  margin-top: -100px;
  padding-top: ac(159px, 100px);
  padding-bottom: ac(77px, 30px);

  background: var(--cl-white);
}

.go-to-connect-hub__title {
  margin-bottom: ac(25px, 10px);

  color: var(--cl-dark-blue);

  font-size: ac(60px, 32px);
  font-weight: 600;
  line-height: 1.2;
}

.go-to-connect-hub__descr {
  max-width: 1364px;

  margin-bottom: ac(54px, 20px);

  * {
    font-size: ac(24px, 16px);
    font-weight: 500;
    line-height: 1.08;
  }
}

.go-to-connect-hub__card-list {
  display: grid;
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: ac(30px, 20px, 1280);

  margin-bottom: ac(86px, 30px);

  @mixin media 1281 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    place-items: center;

    max-width: 700px;

    margin: 0 auto 30px;

    .hub-mini-card {
      max-width: unset;
    }
  }

  @mixin media 651 {
    gap: 10px;
  }

  @mixin media 551 {
    display: none;
  }
}

.hub-mini-card__title-wrap {
  display: flex;
  align-items: center;
  gap: 14px;
}

.hub-mini-card {
  position: relative;

  display: flex;

  width: 100%;
  max-width: 307px;

  padding: ac(24px, 12px, 1280) ac(29px, 18px, 1280);
  border-radius: 10px;

  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  background: var(--gradient-dark-to-light);
  color: var(--cl-white);

  transition: all 250ms ease;

  &:hover {
    .hub-mini-card__title-wrap {
      opacity: 0;
    }
    .hub-mini-card__descr {
      opacity: 1;
    }

    box-shadow: 0 3px 10px 2px var(--cl-secondary);
  }
}

.hub-mini-card__title-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;

  width: 100%;
  align-items: center;
  justify-content: center;

  transition: opacity ease 250ms;
}

.hub-mini-card__logo {
  width: ac(58px, 38px);
}

.hub-mini-card__title {
  display: flex;
  flex-direction: column;

  font-size: ac(40px, 24px);
  line-height: 1;
}

.hub-mini-card__descr {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: auto;

  opacity: 0;

  transition: opacity ease 250ms;
}

.hub-mini-card__text {
  max-height: 160px;

  margin-bottom: ac(16px, 8px);

  font-size: ac(24px, 16px);
  font-weight: 500;
  line-height: 1.08;

  @mixin media 551 {
    font-size: 24px;
  }
}

.hub-mini-card__btn.btn.sm {
  width: 100%;

  @mixin media 551 {
    padding: 12px 15px;

    font-size: 24px;
  }
}

.go-to-connect-hub__btn {
  margin: 0 auto;
}

.go-to-connect-hub__card-list-slider {
  display: none;

  width: 100%;
  max-width: 100vw;

  margin-bottom: 30px;

  @mixin media 551 {
    display: block;
  }

  .hub-mini-card {
    margin: 0 auto;
  }
}

.go-to-connect-hub__card-list-slider-bullets {
  max-width: 300px;

  margin: 40px auto 0;
}
