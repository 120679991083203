.main-hero {
  position: relative;
  z-index: 1;
  overflow: hidden;

  padding-top: ac(204px, 120px);
}

.main-hero__content {
  display: grid;
  grid-template-areas:
    'ti ti ti ti ti ti ti sc sl sl sl sl'
    'cr cr cr cr cr cr cr cr sl sl sl sl';
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 15px;
  width: 100%;

  @mixin media 1025 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
    grid-template-areas:
      'ti ti ti ti ti ti ti sc'
      'cr cr cr cr cr cr cr cr'
      'sl sl sl sl sl sl sl sl';
  }

  @mixin media 551 {
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas:
      'ti'
      'sc'
      'cr'
      'sl';
  }
}

.main-hero__block {
  /* height: 10px; */

  &.title-block {
    grid-area: ti;
  }
  &.socials-block {
    grid-area: sc;
  }
  &.slider-block {
    grid-area: sl;
  }
  &.cards-block {
    grid-area: cr;
  }
  .connect-card-img{
    width: 100%;
  }
}

.title-block {
  position: relative;
  overflow: hidden;

  padding: ac(46px, 20px) ac(55px, 20px) ac(46px, 20px) ac(43px, 20px);
  border-radius: 24px;

  color: var(--cl-white);
}

.title-block__bg {
  position: absolute;
  inset: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title-block__title {
  position: relative;
  z-index: 1;

  margin-bottom: ac(26px, 13px);

  font-size: ac(60px, 30px);
  font-weight: 600;
  line-height: 1.2;

  span {
    color: var(--cl-primary);
  }
}

.title-block__description {
  position: relative;
  z-index: 1;

  * {
    font-size: ac(24px, 16px);
    font-weight: 500;
    line-height: 1.08;
  }
}

.socials-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  padding: ac(41px, 10px) ac(22px, 10px);
  border: 1px solid var(--cl-primary);
  border-radius: ac(24px, 12px);

  @mixin media 551 {
    flex-direction: row;
    justify-content: center;
    gap: 25px;
  }
}

.socials-block__icon {
  font-size: ac(81px, 30px);

  transition: color ease 250ms;

  @mixin media 551 {
    font-size: 56px;
  }

  &:hover {
    color: var(--cl-primary) !important;
  }

  &.icon-linkedin {
    color: var(--cl-secondary);
  }
  &.icon-x {
    color: var(--cl-white);
    font-size: ac(60px, 30px);

    @mixin media 551 {
      font-size: 46px;
    }
  }
  &.icon-pioneer {
    color: #1f54a5;
  }
}

.cards-block {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 16px;

  @mixin media 1025 {
    .connect-card {
      aspect-ratio: unset;
      max-width: unset;
      min-height: 250px;
    }
  }

  @mixin media 651 {
    grid-template-columns: minmax(0, 1fr);
  }
}

.slider-block {
  display: flex;
  flex-direction: column;

  padding: ac(14px, 10px) ac(20px, 10px) ac(20px, 10px);
  border-radius: 16px;

  background: var(--cl-white);

  .connect-card {
    aspect-ratio: unset;
  }
}

.slider-block__title {
  display: flex;
  align-items: center;
  gap: 14px;

  margin-bottom: 4px;
}

.slider-block__title-logo {
  width: ac(58px, 40px);
}

.slider-block__title-text {
  /* max-width: ac(220px, 180px); */
  width: 7ch;

  color: var(--cl-dark-blue);

  font-size: ac(40px, 24px);
  line-height: 1;
}

.slider-block__subtitle {
  margin-bottom: ac(19px, 10px, 1440);
  padding-left: ac(5px, 0px, 1440);

  color: var(--cl-black);

  font-size: ac(40px, 24px);
  line-height: 1.125;
  text-wrap: balance;
}

.slider-block__slider {
  overflow: hidden;

  display: flex;
  flex-direction: column;

  height: 100%;

  padding: 20px;
  margin: -20px;

  .swiper-wrapper {
    height: 100%;
  }

  .swiper-slide {
    height: auto;
  }

  .connect-card {
    width: 100%;
    height: 100%;
  }
}

.slider-block__slider-bullets,
.slider-bullets {
  display: flex;
  justify-content: space-between;

  padding: 0px 7px;
  margin-bottom: 10px;

  .swiper-pagination-bullet {
    width: 100%;
    max-width: 81px;
    height: 10px;
    opacity: 1;

    border-radius: 999px;

    background: var(--cl-gray);

    transition: background ease 250ms;

    &.swiper-pagination-bullet-active {
      background: var(--cl-dark-blue);
    }
  }
}

.scroll-text {
  margin-top: 80px;
  padding-bottom: 35px;

  rotate: -1.65deg;
}

.scroll-text__line {
  position: relative;

  padding: 17px 20px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;

    background: linear-gradient(
      to right,
      var(--cl-primary),
      var(--cl-secondary)
    );

    width: 110vw;
    height: 100%;
  }

  &.to-left-bg {
    &::before {
      background: linear-gradient(
        to left,
        var(--cl-primary),
        var(--cl-secondary)
      );
    }
  }

  /* @mixin media 1025 {
    scale: 0.9;
  } */
}

.scroll-text__text {
  color: var(--cl-white);

  font-size: ac(45px, 22px);
  line-height: 1.08;
  text-align: center;
}
