.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-primary) transparent;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: var(--cl-black);

  color: var(--cl-white);
  font-family: var(--font-main);

  overflow-x: hidden;

  min-height: calc(100 * var(--vh));
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--cl-light-grey);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cl-red);
    border-radius: 0;
  }
}

.grecaptcha-badge {
  z-index: 999999;
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  color: var(--cl-black);
}

[data-simplebar] {
  margin-right: -15px;
  padding-right: 15px;

  .simplebar-content {
    &::after {
      height: 4px;
    }
  }

  .simplebar-scrollbar {
    &::before {
      background: var(--cl-white);
      opacity: 1;
    }
  }
}

/* Animate in view */
[data-aiv^='fade'] {
  --duration: 500ms;
  --delay: 0ms;

  opacity: 0 !important;

  transition: all ease var(--duration) var(--delay);

  @mixin media 551 {
    transition: all ease var(--duration);
  }

  &[data-aiv$='top'] {
    translate: 0 50% !important;
  }
  &[data-aiv$='bottom'] {
    translate: 0 -50% !important;
  }
  &[data-aiv$='left'] {
    translate: 50% 0 !important;
  }
  &[data-aiv$='right'] {
    translate: -50% 0 !important;
  }
  &[data-aiv$='in'] {
    scale: 0 !important;
  }
  &[data-aiv$='out'] {
    scale: 2 !important;
  }

  &.in-view {
    opacity: 1 !important;

    &[data-aiv$='top'],
    &[data-aiv$='bottom'],
    &[data-aiv$='left'],
    &[data-aiv$='right'] {
      translate: 0 0 !important;
    }

    &[data-aiv$='in'],
    &[data-aiv$='out'] {
      scale: 1 !important;
    }
  }
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1468px;

  width: perc(1468, 1920);

  @mixin media 651 {
    width: 87%;
  }

  &.wide {
    max-width: 1680px;
    width: 85.5%;

    @mixin media 651 {
      width: 87%;
    }
  }

  &.sm {
    max-width: 1364px;
    width: 71.6%;

    @mixin media 651 {
      width: 87%;
    }
  }
}

section {
  position: relative;
}

.logo {
  transition: 0.3s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    transform: scale(1.06);
  }
}

#bold-credits {
  width: 148px;
  transition: ease-in-out 0.25s;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }

  @mixin max-sm {
    width: 159px;
  }
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .main {
    flex-grow: 1;
    padding: 0;
  }
}

.cookie-terms-section {
  padding: ac(250px, 140px) 0 ac(150px, 50px);
  p {
    padding: 0;
    margin-bottom: 10px;
  }
  ul,
  ol {
    padding-left: 20px;
    li {
      p {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: circle;
  }

  ol {
    list-style: decimal;
  }

  a {
    display: inline;
    text-decoration: underline;
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
}

.single-page-404 {
  width: 100%;
  min-height: 100vh;
  padding: 20px;

  color: var(--cl-white);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1,
  p {
    margin-bottom: 15px;
    padding: 0;
  }

  a {
    display: inline-block;
    margin-top: 20px;
  }
}

.powered-by-bar {
  position: fixed;
  top: 50vh;
  right: 0;
  transform: rotate(90deg) translateX(50%);
  transform-origin: right top;
  z-index: 2;

  display: flex;
  align-items: center;
  gap: ac(41px, 15px);

  background: var(--cl-gray);

  padding: ac(54px, 20px, 1024) ac(72px, 30px, 1024) ac(23px, 10px, 1024);
  border-radius: 0px 0px 16px 16px;

  transition: background ease 250ms;

  &:hover {
    background: var(--cl-primary);
  }

  @mixin media 901 {
    top: unset;
    bottom: 0;
    right: unset;
    left: 50vw;
    transform: translateX(-50%);

    width: 80%;
    max-width: max-content;
    justify-content: center;

    border-radius: 16px 16px 0px 0px;
    padding: 20px 20px 10px;

    transition: transform 250ms ease;

    &.hidden {
      transform: translate(-50%, 100%);
    }
  }
}

.powered-by-bar__text {
  font-size: ac(24px, 18px, 1024);
  font-weight: 500;
  line-height: 1.375;

  @mixin media 415 {
    font-size: 14px;
  }
}

.powered-by-bar__logo {
  width: ac(264px, 160px, 1024);

  @mixin media 415 {
    width: 110px;
  }
}

.privacy-terms-hero {
  padding-top: ac(330px, 200px);
  padding-bottom: 23px;
}

.privacy-terms-hero__title {
  color: var(--cl-white);

  font-size: ac(60px, 38px);
  font-weight: 600;
  text-align: center;
}

.privacy-terms {
  padding: ac(50px, 30px) 0 ac(130px, 30px);
  background: var(--cl-white);

  .content-element {
    h1 {
      font-size: ac(60px, 38px);
      font-weight: 600;
    }
    h2 {
      font-size: ac(48px, 30px);
      font-weight: 600;
    }
    h3 {
      font-size: ac(38px, 28px);
      font-weight: 600;
    }
    h4 {
      font-size: ac(32px, 24px);
      font-weight: 600;
    }

    * {
      font-size: 24px;
      font-weight: 500;
      line-height: 1.08;

      &:not(:last-child) {
        margin-bottom: 1.65rem;
      }
    }

    a {
      color: var(--cl-secondary);
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
