.powered-by {
  overflow: hidden;

  padding: ac(84px, 30px) 0 ac(58px, 30px);

  color: var(--cl-white);

  background: var(--gradient-light-to-dark);
}

.powered-by__content {
  display: flex;
  align-items: center;
  gap: ac(84px, 20px);

  @mixin media 651 {
    flex-direction: column;
  }
}

.powered-by__img-wrap {
  position: relative;
  top: -14px;
  width: calc(50% - ac(78px, 20px));
  aspect-ratio: 1 / 1;

  @mixin media 651 {
    top: 0;

    width: 100%;
    max-width: 360px;
  }
}

.powered-by__info-wrap {
  width: 50%;
  max-width: 536px;

  @mixin media 651 {
    width: 100%;
    max-width: unset;
  }
}

.powered-by__title {
  margin-bottom: ac(52px, 30px);
}

.powered-by__title-text {
  margin-bottom: ac(35px, 20px);

  font-size: ac(24px, 16px);
  font-weight: 500;
  line-height: 1.08;
}

.powered-by__title-logo {
  width: 100%;
  max-width: ac(440px, 300px);
}

.powered-by__desc {
  margin-bottom: ac(54px, 20px);

  * {
    font-size: ac(24px, 16px);
    font-weight: 500;
    line-height: 1.08;
  }
  p:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.powered-by__visit-btn {
  min-width: ac(357px, 200px);

  margin-bottom: ac(50px, 20px);
}

.powered-by__linkedin-link {
  display: flex;
  gap: ac(22px, 14px);

  width: ac(212px, 141px, 768, 1920);

  margin-left: ac(-5px, 0px);

  &:hover {
    color: var(--cl-secondary);
  }
}

.powered-by__linkedin-link-icon {
  font-size: ac(50px, 35px);

  transition: color ease 250ms;
}

.powered-by__linkedin-link-text {
  max-width: 160px;
  font-size: ac(24px, 16px);
  font-weight: 500;
  line-height: 1.08;

  transition: color ease 250ms;
}
