.contact-section {
  padding: ac(94px, 30px) 0 ac(103px, 30px);

  background: var(--cl-light-gray);

  &.black {
    padding-top: ac(130px, 30px);

    background: var(--cl-black);
    color: var(--cl-white);

    .contact-section__title {
      margin-bottom: ac(95px, 30px);

      color: var(--cl-white);
    }
  }

  &.dark-blue {
    padding-top: ac(86px, 30px);

    background: var(--cl-dark-blue);
    color: var(--cl-white);

    .contact-section__title {
      margin-bottom: ac(30px, 20px);

      color: var(--cl-white);
    }
  }

  &.hero {
    padding-top: ac(380px, 200px);
    padding-bottom: ac(115px, 30px);

    background: linear-gradient(to right, #020c69, #010849);
    color: var(--cl-white);

    .cont {
      max-width: 1364px;
    }

    .contact-section__title {
      margin-bottom: ac(91px, 40px);

      text-align-last: left;

      color: var(--cl-white);
    }

    .contact-section__form {
      margin: 0 auto ac(47px, 30px);
    }

    .contact-section__form-btn {
      margin-top: ac(7px, 0px, 1440);
    }
  }
}

.contact-section__logo-wrap {
  width: ac(400px, 250px);
  max-width: 60%;

  margin: 0 auto ac(54px, 20px);
}

.contact-section__title {
  margin-bottom: ac(30px, 15px);

  color: var(--cl-dark-blue);

  font-size: ac(60px, 30px);
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
}

.contact-section__descr {
  margin-bottom: ac(80px, 30px);

  text-align: center;

  * {
    font-size: ac(24px, 16px);
    font-weight: 500;
    line-height: 1.08;
  }
}

.contact-section__form {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: ac(50px, 20px) ac(134px, 30px, 1024);

  max-width: 1364px;

  margin: 0 auto ac(36px, 20px);

  @mixin media 651 {
    grid-template-columns: minmax(0, 1fr);
  }
}

.contact-section__form-textarea {
  grid-column: 1 / -1;
}

.contact-section__form-btn {
  grid-column: 1 / -1;

  padding: 10px ac(73px, 30px);
  margin-left: auto;

  @mixin media 415 {
    width: 100%;
  }
}

.contact-section__additional-text {
  max-width: 1364px;
  margin: 0 auto;

  * {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.15;
  }

  p:not(:last-child) {
    margin-bottom: 1.25rem;
  }
}
