/* Cookie popup*/
.termsfeed-com---nb-simple, .termsfeed-com---pc-overlay, .termsfeed-com---pc-overlay, .termsfeed-com---palette-dark {

    /* popup colors */

    --cookie-popup-bg: var(--cl-black, #000);
    --cookie-text-color: var(--cl-white, #FFFFFFFF);
    --link-color: var(--cl-primary, #32C2A7FF);
    --cookie-text-hover-color: var(--cl-primary, #32C2A7FF);
    --font-size: 16px;
    --font-family: var(--font-main);

    --cookie-popup-border-color: var(--cl-secondary, #9170F7FF);
    --cooki-preferences-head-bg: var(--cl-dark-blue, #0C015BFF);
    --tabs-item-link-hover: var(--cl-white);
    --cooki-preferences-footer-bg: var(--cl-dark-blue, #0C015BFF);

    --nav-link-color-hover: var(--white, #ffffff);
    --nav-link-activ-bg: var(--cl-dark-blue, #0C015BFF);

    --button-color: var(--cl-white, #ffffff);
    --button-color-hover: var(--cl-white, #ffffff);
    --button-bg: transparent;
    --button-bg-hover: transparent;
    --button-padding:7px ac(20px, 19px) 10px;
    --button-font-size: ac(20px, 16px);
    --button-font-weighht: 700;
    --button-font-family: var(--font-main);
    --button-radius: 999px;
    --button-min-height: ac(47px, 38px);
    --button-border: 1px solid var(--cl-secondary);
    --button-hover-border: 1px solid var(--cl-secondary);

    --active-switcher-bg: var(--cl-primary, #32C2A7FF)

}
.termsfeed-com---nb .cc-nb-main-container{
    padding: ac(66px, 33px) !important;
}
.termsfeed-com---palette-dark.termsfeed-com---nb {
    background-color: var(--cookie-popup-bg) !important;
    color: var(--cookie-text-color) !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);

}

.termsfeed-com---reset {
    font-family: var(--font-main) !important;

    * {
        font-family: var(--font-main) !important;
    }
}

.termsfeed-com---palette-dark .cc-nb-title, .termsfeed-com---palette-dark .cc-nb-text {
    color: inherit !important;

}

.termsfeed-com---palette-dark .cc-nb-okagree, .termsfeed-com---palette-dark .cc-nb-reject, .termsfeed-com---palette-dark .cc-cp-foot-save, .termsfeed-com---palette-dark .cc-nb-changep {
    background: var(--button-bg) !important;
    border: var(--button-border);
    box-shadow: none;
    transition: color 0.3s, background 0.3s;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: var(--button-padding);
    font-weight: 600;
    cursor: pointer;
    border-radius: var(--button-radius);
    align-self: center;
    font: var(--button-font-size) var(--button-font-family)!important;
    min-height: var(--button-min-height);
    font-weight: var(--button-font-weighht)!important;
    color: var(--button-color) !important;
    position: relative;
    overflow: hidden;
    transform: translateY(0);
    &:hover {
        border: var(--button-hover-border);
        background: var(--button-bg-hover) !important;
        color: var(--button-color-hover) !important;
        &::before {
            top: 0;
        }
    }
    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 100%;
        background: var(--cl-secondary);
        border-radius: inherit;
        transition: top 0.3s;
        z-index: -1;
    }

}


.termsfeed-com---palette-dark .cc-pc-head {
    background: var(--cooki-preferences-head-bg) !important;
    color: var(--cookie-text-color) !important;
    border-bottom: 1px solid var(--cookie-popup-border-color) !important;
}

.termsfeed-com---palette-dark .cc-pc-head-title-headline {
    color: inherit !important;
}

.termsfeed-com---palette-dark .cc-pc-head-close {
    color: inherit !important;
    border: 0!important;
    transition: color 0.3s;

    &:hover {
        color: var(--cookie-text-hover-color) !important;
    }
}

.termsfeed-com---palette-dark .cc-cp-foot {
    border-top-color: var(--persimmon) !important;
}

.termsfeed-com---pc-dialog input[type=checkbox].cc-custom-checkbox:checked + label:before {
    background: var(--active-switcher-bg) !important;
}

.termsfeed-com---palette-dark .cc-pc-head-lang select {
    border: 1px solid var(--cookie-popup-border-color) !important;
    color: var(--cookie-text-color) !important;
    background: transparent;
    cursor: pointer;
}

.termsfeed-com---palette-dark .cc-cp-body-tabs-item[active=true] button {
    color: var(--white) !important;
    background: var(--persimmon) !important;
}

.termsfeed-com---pc-dialog .cc-cp-body-content-entry[active=true]{
    background: transparent !important;
    color: var(--cookie-text-color) !important;
}
.termsfeed-com---palette-dark .cc-cp-foot {
    background: var(--cooki-preferences-footer-bg) !important;
    color: var(--cookie-text-color) !important;
}

.termsfeed-com---palette-dark .cc-cp-body-tabs-item, .termsfeed-com---palette-dark .cc-cp-body-tabs {
    background: var(--cookie-) !important;
}

.termsfeed-com---pc-dialog .cc-cp-body-tabs-item {
    border-color: var(--cookie-popup-border-color) !important;
    box-sizing: border-box!important;
}

.termsfeed-com---palette-dark .cc-cp-body-tabs .cc-cp-body-tabs-item[active=true] {
    background: var(--nav-link-activ-bg) !important;
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry-title, .termsfeed-com---palette-dark .cc-cp-body-content-entry-text {
    color: inherit !important;
}

.termsfeed-com---palette-dark .cc-cp-body .cc-cp-body-content {
    background: var(--white) !important;
}

.termsfeed-com---palette-dark .cc-cp-foot-byline {
    color: var(--black) !important;
}

.termsfeed-com---palette-dark .cc-pc-container {
    background: var(--cookie-popup-bg) !important;
    .cc-cp-body {
        background: var(--cookie-popup-bg) !important;
        color: var(--cookie-text-color) !important;
    }

}
.termsfeed-com---pc-dialog .cc-cp-foot-byline a {
    color: var(--link-color) !important;
    display: inline;
    text-decoration: underline;
    &:hover{
        text-decoration: none;
    }
}

.termsfeed-com---palette-dark .cc-cp-body-tabs-item-link {
    color: var(--cookie-text-color) !important;
    font-family: var(--font-main)!important;
    font-size: 14px;
    border-radius: 0;
    transition: 0.3s;

    &:hover {
        background: var(--button-bg-hover);
        color: var(--tabs-item-link-hover) !important;
    }
}

.termsfeed-com---palette-dark .cc-pc-head-lang select:focus {
    box-shadow: 0 0 0 2px var(--persimmon) !important;
}

.termsfeed-com---palette-dark .cc-cp-body-tabs-item-link:focus {
    box-shadow: none !important;
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry a {
    color: var(--link-color) !important;
    display: inline !important;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}
.termsfeed-com---nb .cc-nb-text{
    font: var(--font-size) var(--font-family) !important;
}

@media only screen and (max-width: 320px) and (min-height: 480px) {
    .termsfeed-com---nb-simple {
        height: auto !important;
    }
}

.termsfeed-com---palette-dark .cc-nb-okagree:focus {
    box-shadow: none !important;
}

.termsfeed-com---pc-dialog input[type=checkbox].cc-custom-checkbox+label:after{
    background: var(--amazon-green, #346C50FF);
}
.cc-nb-title-container, .cc-nb-text-container{
    position: relative;
    z-index: 1;
}

.termsfeed-com---palette-dark .cc-pc-head-title-text{
    color: var(--text-color) !important;
}
.termsfeed-com---nb .cc-nb-main-container{
    border: 1px solid var(--cl-secondary);
}

@media (max-width: 650px) {
    .termsfeed-com---pc-dialog .cc-pc-head-lang{
        padding: 15px 10px 15px 15px!important;
    }
    .termsfeed-com---palette-dark .cc-pc-head-lang select{
        max-width: 80%;
    }
}

/* Cookie popup end*/