.article-hero {

  --mb: ac(145px, 65px);
  min-height: ac(830px, 580px, 1024);
  padding-top: ac(291px, 120px);

  margin-bottom: calc(var(--mb) * -1);
  overflow: hidden;

  background: var(--gradient-light-to-dark);

  @mixin media 769 {
    margin-bottom: -55px;
  }

  @mixin min-media 1540 {
    min-height: 930px;
  }

  .cont {
    display: flex;
    justify-content: space-between;
    gap: 40px 20px;
    max-width: 1378px;

    @mixin media 769 {
      flex-direction: column;
      align-items: center;
    }
  }
}

.article-hero__info {
  max-width: 570px;
  padding-bottom: calc(var(--mb) + 50px);
}

.article-hero__card-wrap {
  z-index: 1;

  max-width: ac(561px, 450px, 1440);
}

.article-hero__type {
  margin-bottom: ac(28px, 15px, 1280);

  @mixin media 769 {
    text-align: center;
  }
}

.article-hero__additional {
  margin-bottom: ac(94px, 40px, 1280);

  @mixin media 769 {
    text-align: center;
  }
}

.article-hero__type,
.article-hero__additional {
  color: var(--cl-primary);

  font-size: ac(30px, 20px, 1280);
  font-weight: 500;
  line-height: 1.08;
}

.article-hero__title {
  margin-bottom: ac(34px, 20px, 1280);

  color: var(--cl-white);

  font-size: ac(60px, 40px, 1280);
  font-weight: 400;
  line-height: 1.08;
  text-wrap: balance;

  @mixin media 769 {
    text-align: center;
  }
}

.article-hero__share-title {
  margin-bottom: ac(15px, 10px, 1280);

  color: var(--cl-white);

  font-size: ac(30px, 20px);
  font-weight: 500;
  line-height: 1.08;

  @mixin media 769 {
    text-align: center;
  }
}

.article-hero__share-list {
  display: flex;
  gap: 13px;

  @mixin media 769 {
    justify-content: center;
  }
}

.article-hero__share-item {
  display: flex;
  justify-content: center;
  align-items: center;

  width: ac(54px, 40px, 1280);
  height: ac(54px, 40px, 1280);

  background: var(--cl-white);
  color: var(--cl-dark-blue);

  border-radius: 5px;

  transition: color 250ms ease;

  &:hover {
    color: var(--cl-primary) !important;
  }

  &.circle {
    border-radius: 999px;

    background: var(--cl-black);
    color: var(--cl-white);
  }

  i {
    font-size: ac(32px, 24px);
  }
}

.article-content {
  padding-top: ac(121px, 100px);
  padding-bottom: ac(134px, 50px);

  background: var(--cl-white);

  .content-element, {

    transition: all ease 250ms;

    &:not(:last-child) {
      margin-bottom: ac(45px, 20px);
    }
  }
}

.article-content__back-link {
  margin-bottom: 37px;

  font-size: 24px;
  font-weight: 500;
  line-height: 1.08;

  transition: color 250ms ease;

  &:hover {
    color: var(--cl-secondary);
  }
}
.article-content-buttons{
  padding-top: 25px;
  margin-bottom: ac(71px, 30px);
  display: flex;
  flex-wrap: wrap;
  gap: 25px 20px;
  .btn{
    margin: 0!important;
  }
}
.article-content__btn {
  width: 100%;
  max-width: 362px;

  &:not(:last-child) {
    margin-bottom: ac(71px, 30px);
  }

  &:last-child {
    margin-top: ac(76px, 30px);
  }
}

.latest-articles {
  padding: ac(120px, 30px) 0 ac(104px, 30px);

  background: var(--cl-gray);

  .cont {
    position: relative;
  }
}

.latest-articles-slider {
  overflow: hidden;
  max-width: 100vw;
  padding: 50px 20px;
  width: calc(100% + 40px);
  margin-left: -20px;

  .slider-wrapper {
    padding: 0 50px;
    margin: 0 -50px;
  }

  .swiper-slide {
    height: auto;
  }

  .article-card {
    height: 100%;

    .article-card__descr {
      margin-bottom: ac(65px, 30px);
    }
  }

}

.latest-articles-slider__btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.latest-articles-slider__btn {
  position: absolute;
  top: 50%;

  width: 40px;
  height: 80px;

  transition: scale 400ms ease;

  &:hover {
    scale: 1.05;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% 0;

    width: 100%;
    height: 4px;

    background: var(--cl-primary);
  }

  &.prev {
    right: calc(100% + 10px);

    &::before {
      rotate: -45deg;
      transform-origin: left bottom;
    }
    &::after {
      rotate: 45deg;
      transform-origin: left top;
    }
  }

  &.next {
    left: calc(100% + 10px);

    &::before {
      rotate: -45deg;
      transform-origin: right top;
    }
    &::after {
      rotate: 45deg;
      transform-origin: right bottom;
    }
  }

  @mixin media 651 {
    position: relative;
    top: unset;

    &.prev {
      right: unset;
    }
    &.next {
      left: unset;
    }
  }
}

@media (max-width: 500px) {
  .latest-articles{
    overflow: hidden;
  }
  .latest-articles-slider{
    overflow: visible;
  }
}