.input,
.textarea {
  @mixin transparent-bg-input var(--cl-black);

  background: var(--cl-white);
  color: var(--cl-black);

  font-size: ac(24px, 16px);
  font-weight: 500;
  line-height: 1.08;

  outline: 1px solid transparent;

  transition: background 250ms ease;

  &::placeholder {
    color: var(--cl-black);
  }
}

.input {
  border-radius: 999px;
  padding: 13px ac(46px, 30px);
}

.textarea {
  min-height: 300px;

  border-radius: 16px;
  padding: ac(31px, 20px) ac(46px, 30px);
}
