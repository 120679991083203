.hub-filter {
  padding-top: ac(260px, 120px);
  padding-bottom: 20px;

  color: var(--cl-white);
}

.hub-filter__title {
  margin-bottom: ac(36px, 20px);

  font-size: ac(60px, 32px);
  font-weight: 600;
  text-align: center;

  span {
    color: var(--cl-secondary);
  }
}

.hub-filter__descr {
  width: 100%;
  max-width: 1088px;

  margin: 0 auto ac(56px, 25px);

  color: var(--cl-secondary);

  text-align: center;

  * {
    font-size: ac(24px, 16px, 1280);
    font-weight: 500;
    line-height: 1.08;
  }
}

.hub-filter__filter-wrap {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  padding: 0 ac(10px, 0px, 1440);

  @mixin media 1025 {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.hub-filter__item {
  position: relative;

  width: 100%;
  max-width: 261px;
}

.hub-filter__radio {
  position: absolute;
  visiblity: hidden;
  opacity: 0;

  &:checked + .hub-filter__item-title {
    border-color: transparent;

    &::before {
      translate: 0;
    }
  }
}
.hub-filter__item-title {
  position: relative;
  overflow: hidden;
  z-index: 1;
  border: 2px solid var(--cl-white);
  border-radius: 999px;
  padding: ac(13px, 8px, 1280);
  text-align: center;

  transition: all 250ms ease;
  cursor: pointer;

  font-size: ac(24px, 20px, 1280);
  font-weight: 500;
  line-height: 1.375;
  text-transform: uppercase;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    background: var(--cl-secondary);
    border-radius: 999px;

    translate: 0 100%;

    transition: all 250ms ease;
  }
  &:hover{
    background: var(--cl-dark-blue);
  }
}

.hub-result {
  padding: 15px ac(15px, 0px, 1440) ac(67px, 20px);

  .not-found {
    grid-column: 1 / -1;

    margin: 20px auto 0;

    color: var(--cl-white);
  }
}

.hub-result__list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: ac(88px, 30px, 1024) 16px;

  @mixin media 1025 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @mixin media 769 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @mixin media 501 {
    grid-template-columns: minmax(0, 1fr);
  }
}

.article-card {
  display: flex;
  flex-direction: column;

  .connect-card-img {
    margin-bottom: ac(37px, 20px);
  }

  &.black-text {
    .article-card__type,
    .article-card__title,
    .article-card__descr {
      color: var(--cl-black);
    }
  }
}

.article-card__type,
.article-card__title,
.article-card__descr {
  color: var(--cl-white);
}

.article-card__type {
  margin-bottom: ac(27px, 14px);

  font-size: ac(24px, 18px, 1280);
  font-weight: 500;
  line-height: 1.08;
  text-transform: uppercase;
}

.article-card__title {
  margin-bottom: ac(33px, 15px, 1024);

  font-size: ac(40px, 24px, 1280);
  line-height: 1.08;
}

.article-card__descr {
  max-height: 86px;

  margin-bottom: ac(38px, 20px);

  * {
    font-size: ac(24px, 16px, 1280);
    font-weight: 500;
    line-height: 1.08;
  }
}

.article-card__link.btn {
  padding: ac(15px, 10px, 1280) ac(57px, 25px, 1280);

  margin-top: auto;
}

.hub-result__pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  margin-top: ac(76px, 20px);

  color: var(--cl-white);
}

.hub-result__pagination-item,
.hub-result__pagination-arrow {
  min-width: ac(30px, 20px);

  font-size: ac(40px, 20px);
  line-height: 1.08;

  transition: color ease 250ms;

  &:hover,
  &.active {
    color: var(--cl-primary);
  }
}
