@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@300;400;500;600;700&display=swap');

:root {
  --cl-primary: #32c2a7;
  --cl-secondary: #9170f7;
  --cl-black: #000000;
  --cl-dark-blue: #0c015b;
  --cl-white: #ffffff;
  --cl-gray: #d1d1d1;
  --cl-light-gray: #efefef;
  --gradient-dark-to-light: linear-gradient(to right, #01052e, #020c69);
  --gradient-light-to-dark: linear-gradient(to left, #01052e, #020c69);

  --font-main: 'Manrope', sans-serif;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1200;

  --vh: calc(100vh / 100);
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
/* @custom-media --mobile-menu-start-point (max-width: 1200px); */

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
/* @custom-media --mobile-menu-end-point (min-width: 1201px); */
