/* ----------------------- Common desktop header ----------------------- */
.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: ac(26px, 13px, 1024) 0;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &:before {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background-color: transparent;
    opacity: 0;
    transition: all 0.25s ease;
  }

  .logo {
    height: 100%;
    width: ac(400px, 200px);
    flex-shrink: 0;
    transition: transform 0.25s ease, width 0.25s ease;
    z-index: 100;
    margin-right: auto;

    &:hover {
      transform: scale(1.06);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
    }

    @mixin max-xs {
      width: 180px;
    }
  }

  .navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .burger {
    display: none;
  }

  .navbar-nav {
    display: flex;

    .menu-list {
      display: flex;
      flex-grow: 1;
    }

    &__decor {
      display: none;
    }
  }

  .menu {
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    gap: ac(129px, 20px);

    border: 1px solid var(--cl-primary);
    padding: ac(28px, 15px) ac(67px, 20px);
    border-radius: 21px;

    @mixin mobile-menu-end-point {
      width: fit-content;
      flex: 1;
      padding-left: ac(60px, 20px);
      align-items: center;

      .simplebar-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .menu-list {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: ac(36px, 20px);
        }

        &:after {
          display: none;
        }
      }
    }

    /* &:not([data-simplebar='init']) {
      display: none;
    } */
  }

  .menu-item {
    position: relative;

    &.dropdown {
      & > .menu-link {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        &::after {
          content: '\e999';
          font-family: 'icomoon';
          font-size: ac(10px, 8px);
          padding-left: 10px;
        }
      }
    }
  }

  .menu-additional-content {
    display: none;

    @mixin mobile-menu-start-point {
      /*transform: translateY(-8px);*/
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin-top: auto;

      .btn {
        width: 100%;
      }
    }
  }

  &:not(.header-mobile) {
    .menu-item {
      @mixin mobile-menu-end-point {
        padding: 5px 0;
      }

      &:hover {
        @mixin mobile-menu-end-point {
          .dropdown-menu {
            display: block;
          }
        }
      }
    }
  }

  .menu-link {
    position: relative;
    font-size: ac(24px, 16px);
    font-family: var(--font-main);
    line-height: normal;
    font-weight: 500;
    color: var(--cl-white);
    transition: all 0.3s ease;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      color: var(--cl-primary);
    }

    &.active {
      color: var(--cl-primary);
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    padding: 10px 10px 10px 15px;
    border-radius: 10px;
    background: var(--cl-grey);
    box-sizing: content-box;
    display: none;

    li {
      .menu-link {
        padding: 5px 0;
        text-transform: capitalize;
      }
    }
  }
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@mixin mobile-menu-start-point {
  .header:not(.header-mobile) {
    .burger,
    .menu-toggle {
      display: flex;
    }

    .navbar-nav {
      position: fixed;
      right: 0;
      top: 0;
      width: 50%;
      height: calc(var(--vh, 1vh) * 100);
      min-width: 320px;
      background: var(--cl-black);
      padding: 132px 40px 40px;
      transition: transform 0.3s ease-out;
      overflow: hidden;

      @media screen and (max-height: 730px) {
        padding-top: 110px;
      }

      &.nav-slide-left {
        right: auto;
        left: 0;
        transform: translateX(-100%);

        &.active {
          transform: translateX(0);
        }
      }

      &.nav-slide-right {
        right: 0;
        left: auto;
        transform: translateX(100%);

        &.active {
          transform: translateX(0);
        }
      }

      &.nav-slide-down {
        right: auto;
        left: 0;
        transform: translateY(-100%);

        &.active {
          transform: translateY(0);
        }
      }

      &.nav-slide-up {
        right: auto;
        left: 0;
        transform: translateY(100%);

        &.active {
          transform: translateY(0);
        }
      }

      .menu {
        width: 100%;
        max-height: 100%;
        min-height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        position: relative;
        gap: 50px;
        z-index: 2;
        overflow-x: hidden;
        overflow-y: auto;

        .simplebar-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;

          .menu-list {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
          }
        }

        .simplebar-track.simplebar-vertical {
          width: ac(6px, 4px);
          background: var(--cl-medium-grey);
          border-radius: 4px;
          /*transform: translateX(ac(6px, 4px));*/
          .simplebar-scrollbar {
            background: var(--cl-red);
            border-radius: 4px;
            &:before {
              content: none;
            }
          }
        }
      }

      .menu-link {
        padding: 0;
        font-size: ac(32px, 28px, 414, 551);
        font-weight: 600;
      }

      .menu-item {
        width: 100%;
        text-align: center;

        &:last-of-type {
          @media screen and (max-height: 730px) {
            margin-bottom: 28px;
          }
        }
      }

      @mixin media 1024 {
        width: 100%;

        &:after {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          height: 80px;
          width: calc(100% - 25.6vw);
          background: var(--cl-light-grey);
        }
      }
    }

    .dropdown-menu {
      position: relative;
      top: 0;
    }
  }
}

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
  position: relative;
  z-index: 100;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 28px;

  transition: all 400ms ease;

  span {
    transition: all 400ms ease;
    user-select: none;
    width: 100%;
    height: 4px;
    background: var(--cl-gray);
    border-radius: 99px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;

    user-select: none;
    width: 100%;
    height: 4px;
    background: var(--cl-gray);
    border-radius: 99px;

    transition: all ease 250ms;
  }

  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }

  &:hover {
    span {
      scale: 1.25 1;
    }
  }

  &.active {
    &:hover {
      scale: 1.25;
    }

    span {
      opacity: 0;
      scale: 0.5;
    }
    &::before {
      top: 50%;
      translate: 0 -50%;
      rotate: 45deg;
    }
    &::after {
      bottom: 50%;
      translate: 0 50%;
      rotate: -45deg;
    }
  }
}

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}
/* end of HEADER COMPONENTS */
