.connect-card {
  position: relative;
  overflow: hidden;
  z-index: 1;

  aspect-ratio: 350 / 433;

  max-width: 400px;

  padding: ac(42px, 20px) ac(42px, 20px, 1280) ac(22px, 12px);
  border-radius: 16px;
  border: 2px solid transparent;

  background: var(--cl-gray);

  box-shadow: 0 2px 5px 1px transparent;

  transition: all ease 250ms;

  &:hover {
    box-shadow: 0 2px 5px 1px var(--cl-gray);
  }

  @mixin media 1281 {
    aspect-ratio: unset;
  }

  @mixin media 769 {
    width: 100%;
    max-width: 600px;
  }

  &.learn {
    background: var(--cl-secondary);

    &:hover {
      box-shadow: 0 2px 5px 1px var(--cl-secondary);
    }

    .connect-card__bg {
      display: none;
    }

    .connect-card__subtitle,
    .connect-card__additional-info {
      color: var(--cl-white);
    }

    .connect-card__title {
      color: var(--cl-black);
    }
  }

  &.listen {
    background: var(--cl-primary);
    border-color: var(--cl-primary);

    &:hover {
      box-shadow: 0 2px 5px 1px var(--cl-primary);
    }

    .connect-card__type {
      color: var(--cl-white);
    }

    .connect-card__subtitle,
    .connect-card__additional-info {
      color: var(--cl-primary);
    }

    .connect-card__title {
      color: var(--cl-white);
    }
  }

  &.grow {
    .connect-card__bg {
      display: none;
    }
  }

  &.meet {
    background: #01052d;
    border-color: #01052d;

    &:hover {
      box-shadow: 0 2px 5px 1px var(--cl-secondary);
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 1;

      background: var(--gradient-dark-to-light);

      opacity: 0.71;
    }

    .connect-card__type {
      color: var(--cl-white);
    }

    .connect-card__subtitle,
    .connect-card__additional-info {
      color: var(--cl-primary);
    }

    .connect-card__title {
      color: var(--cl-white);
    }

    .connect-card__bg {
      object-position: right;
    }
  }

  &.xl {
    max-width: unset;

    padding: ac(21px, 15px) ac(28px, 15px) ac(19px, 15px);

    .connect-card__type-wrap {
      gap: ac(21px, 10px);
    }

    .connect-card__logo {
      width: ac(93px, 45px);
    }

    .connect-card__type {
      font-size: ac(60px, 30px);
      line-height: 1.033;
    }

    .connect-card__subtitle,
    .connect-card__additional-info {
      font-size: ac(30px, 20px);
    }

    .connect-card__title {
      margin-bottom: 2rem;

      font-size: ac(40px, 24px);
      line-height: 1.55;
    }
  }

  &.xxl {
    max-width: unset;

    padding: ac(52px, 30px) ac(43px, 20px) ac(59px, 30px) ac(71px, 40px);
    border-radius: ac(30px, 24px);

    .connect-card__type-wrap {
      gap: ac(21px, 10px);
    }

    .connect-card__logo {
      width: ac(93px, 45px);
    }

    .connect-card__type {
      font-size: ac(60px, 30px);
      line-height: 1.033;
    }

    .connect-card__subtitle,
    .connect-card__additional-info {
      font-size: ac(30px, 20px);
    }

    .connect-card__subtitle {
      margin-bottom: 20px;
    }

    .connect-card__title {
      margin-bottom: 28px;

      font-size: ac(60px, 30px);
      line-height: 1.08;
    }
  }
}

.connect-card__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
}

.connect-card__bg {
  position: absolute;
  inset: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.connect-card__type-wrap {
  position: relative;
  z-index: 1;

  margin-bottom: ac(50px, 40px);

  display: flex;
  align-items: center;
  gap: ac(15px, 7px, 1280);
}

.connect-card__logo {
  flex-shrink: 0;

  width: ac(58px, 38px, 1280);
}

.connect-card__type {
  max-width: 8ch;

  color: var(--cl-dark-blue);

  font-size: ac(40px, 24px, 1024);
  line-height: 1;
}

.connect-card__info-wrap {
  position: relative;
  z-index: 1;
}

.connect-card__subtitle,
.connect-card__additional-info {
  color: var(--cl-secondary);

  font-size: ac(24px, 14px);
  font-weight: 500;
  line-height: 1.08;
  text-transform: uppercase;
}

.connect-card__title {
  margin-bottom: 1.5rem;

  font-size: ac(30px, 16px);
  line-height: 1.4;
  text-transform: capitalize;
  text-wrap: balance;
}

.connect-card-img {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    filter: blur(10px);
    transition: opacity linear 250ms;
    background: var(--cl-secondary);
    opacity: 0;
  }
  &:hover {
    &::before {
      opacity: 1;
    }
  }
}

.connect-card-img__img {
  position: relative;
  z-index: 1;

  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: filter ease 250ms;
}
