.footer {
  padding: ac(104px, 40px) 0 0;

  color: var(--cl-white);

  .cont {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    flex-wrap: wrap;

    @mixin media 1025 {
      flex-direction: wrap-reverse;
      gap: 30px;
    }
  }
}

.footer__logo {
  width: 100%;
  max-width: ac(398px, 200px);

  margin-bottom: ac(52px, 20px);

  transition: scale 250ms ease;

  &:hover {
    scale: 1.05;
  }
}

.footer__logo-column {
  width: fit-content;

  @mixin media 1025 {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.footer__socials {
  display: flex;
  align-items: center;
  gap: ac(42px, 22px);
  justify-content: space-between;

  padding: ac(16px, 8px) 0;
}

.footer__socials-title {
  flex-shrink: 0;
  max-width: 8ch;

  font-size: ac(24px, 16px);
  font-weight: 500;
  line-height: 1.08;
}

.footer__socials-list {
  display: flex;
  gap: 31px;
}

.footer__socials-link {
  font-size: ac(57px, 28px);

  transition: color 250ms ease;

  &:hover {
    color: var(--cl-primary);
  }
}

.footer__column {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  min-width: ac(400px, 200px, 1280);

  @mixin media 1025 {
    min-width: unset;

    gap: 10px;
  }
}

.footer__column-link {
  width: fit-content;

  font-size: ac(24px, 16px);
  font-weight: 500;
  line-height: 1.08;

  transition: color 250ms ease;

  &.address {
    max-width: 310px;
  }

  &:hover {
    color: var(--cl-secondary);
  }

  @mixin media 551 {
    width: 100%;
    text-align: center;
  }
}

.footer__column-link-list {
  display: flex;
  gap: ac(140px, 30px, 1280);

  padding-top: ac(7px, 0px);

  @mixin media 1025 {
    width: 100%;
    justify-content: center;
  }

  @mixin media 551 {
    flex-direction: column;
  }
}

.cont.footer__credits {
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: ac(74px, 30px) 0px ac(69px, 30px);

  font-size: ac(20px, 12px);
  line-height: 1.08;
  gap: ac(23px, 10px);
}

.footer__credits-item {
  border-left: 1px solid var(--cl-white);
  padding-left: ac(23px, 10px);

  border-right: 1px solid var(--cl-white);
  padding-right: ac(23px, 10px);
  &:not(:first-child) {
    border-right: 0;
    padding-right: 0;
  }
  &:not(:last-child) {
    border-left: 0;
    padding-left: 0;
  }
}
