@font-face {
  font-family: 'icomoon';
  src: url('../../assets/fonts/icomoon.eot?5y7hwa');
  src: url('../../assets/fonts/icomoon.eot?5y7hwa#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/icomoon.woff2?5y7hwa') format('woff2'),
    url('../../assets/fonts/icomoon.ttf?5y7hwa') format('truetype'),
    url('../../assets/fonts/icomoon.woff?5y7hwa') format('woff'),
    url('../../assets/fonts/icomoon.svg?5y7hwa#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: '\e904';
}
.icon-linkedin-inner:before {
  content: '\e905';
}
.icon-mail:before {
  content: '\e903';
}
.icon-pioneer:before {
  content: '\e900';
}
.icon-linkedin:before {
  content: '\e901';
}
.icon-x:before {
  content: '\e902';
}
