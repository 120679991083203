.btn {
  position: relative;
  overflow: hidden;
  z-index: 1;

  display: block;

  width: fit-content;

  padding: 15px 57px;
  border: 1px solid var(--cl-secondary);
  border-radius: 999px;

  color: var(--cl-black);
  line-height: 1;
  font-size: ac(24px, 16px);
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;

  &.sm {
    padding: 7px 15px;
    font-size: ac(24px, 14px, 1024);
  }

  &.white-text {
    color: var(--cl-white);
  }

  &.white-border {
    border-color: var(--cl-white);
  }

  &.white {
    background: var(--cl-white);
    border-color: transparent;
  }

  &.gray {
    background: var(--cl-gray);
  }

  &.gradient {
    color: var(--cl-white);

    background: var(--gradient-dark-to-light);

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      z-index: -1;

      border: 1px solid var(--cl-dark-blue);
      border-radius: 999px;

      opacity: 0;

      transition: all ease 250ms;
    }

    &::before {
      background: var(--cl-white);
    }

    &:hover {
      color: var(--cl-dark-blue);

      &::after {
        opacity: 1;
      }
    }
  }

  &:hover {
    &::before {
      translate: 0;
    }
  }

  &::before {
    content: '';
    position: absolute;
    inset: -1px;
    z-index: -1;

    width: calc(100% + 2px);
    height: calc(100% + 2px);

    border-radius: 999px;

    background: var(--cl-secondary);

    translate: 0 100%;

    transition: translate ease 250ms;
  }
}
